import { Flex, FlexProps, Grid, Text, Textarea } from '@chakra-ui/react';
import {
  checkRecaptcha,
  deleteCookie,
  formatDate,
  isStringValid,
  useReservationState,
  validateEmail,
  validateName,
  validatePhone,
} from 'utils';
import { Button, Dropdown, Input } from 'components/global';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from 'react';
import { createReservation } from 'utils/api';
import { useNavigate } from 'react-router-dom';

export function CheckoutForm() {
  const router = useNavigate();
  const { updateReservation, reservationState } = useReservationState();
  const [recaptchaCheck, setRecaptchaCheck] = useState(false);
  const { name, phone, email, age, message } = reservationState;
  const [isValidName, setIsValidName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [trySubmit, setTrySubmit] = useState(false);
  const dropDownAge: { code: string; label: string; id: number }[] = [];
  for (let index = 18; index <= 100; index++) {
    dropDownAge.push({
      code: index.toString(),
      label: index.toString(),
      id: index,
    });
  }

  function handleValidateName(nameInput: string) {
    setIsValidName(validateName(nameInput) && (isStringValid(name) || trySubmit));
  }

  function handleValidatePhone(phoneInput: string) {
    setIsValidPhone(validatePhone(phoneInput) && (isStringValid(phone) || trySubmit));
  }

  function handleValidateEmail(emailInput: string) {
    setIsValidEmail(validateEmail(emailInput) && (isStringValid(email) || trySubmit));
  }

  const { trigger, isMutating } = createReservation({
    ...reservationState,
    hourDropOff: formatDate(reservationState.hourDropOff, 'HH:mm'),
    hourPickUp: formatDate(reservationState.hourPickUp, 'HH:mm'),
  });

  return (
    <Flex {...formWrapperStyle}>
      <Grid {...gridWrapperStyle}>
        <Flex flexDirection="column">
          <Text>Nume:</Text>
          <Input
            name="name"
            placeholderText="Nume si prenume"
            error={!isValidName ? 'Trebuie sa intoroduci un nume valid' : null}
            // onBlur={(event: any) => handleValidateName(event.target.value)}
            onChange={(event) => {
              handleValidateName(event.target.value);
              updateReservation({
                name: event.target.value,
              });
            }}
            value={name}
            {...inputStyle}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text>Telefon:</Text>
          <Input
            name="phone"
            placeholderText="Numarul de telefon"
            error={!isValidPhone ? 'Trebuie sa intoroduci un numar de telefon valid' : null}
            onChange={(event) => {
              handleValidatePhone(event.target.value);
              updateReservation({
                phone: event.target.value,
              });
            }}
            value={phone}
            {...inputStyle}
          />
        </Flex>
      </Grid>

      <Grid {...gridWrapperStyle}>
        <Flex flexDirection="column">
          <Text>Email:</Text>
          <Input
            name="email"
            placeholderText="Email"
            value={email}
            error={!isValidEmail ? 'Trebuie sa intoroduci un email valid' : null}
            onChange={(event) => {
              handleValidateEmail(event.target.value);
              updateReservation({
                email: event.target.value,
              });
            }}
            {...inputStyle}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text alignSelf="flex-start">Varsta:</Text>
          <Dropdown
            selectedId={age}
            matchWidth
            options={dropDownAge}
            onChange={(value) => {
              updateReservation({
                age: value?.code,
              });
            }}
            dropdownStyles={dropDownStyle}
          />
        </Flex>
      </Grid>
      <Flex flexDirection="column" mt="md">
        <Text>Mesajul tau!</Text>
        <Textarea
          placeholder="Zbor, scaun de copil, iesire din tara etc"
          lineHeight="10"
          value={message}
          {...inputStyle}
          onChange={(event) => {
            updateReservation({
              message: event.target.value,
            });
          }}
        />
      </Flex>

      <Flex flexDirection="column">
        <ReCAPTCHA
          style={{ marginTop: '16px' }}
          sitekey="6Ld-3pMoAAAAAHstd4JtGgRnjVCg0P_5csYTWHQV"
          onChange={(value) => {
            if (value) {
              checkRecaptcha(value, (resp) => setRecaptchaCheck(resp));
            }
          }}
        />
        {!recaptchaCheck && trySubmit && (
          <Text {...errorMsgStyle} id="recaptcha-error">
            Te rugăm să completezi reCAPTCHA pentru a continua.
          </Text>
        )}
      </Flex>

      <Button
        size="full"
        variant="primary"
        // isDisabled={}
        mt="md"
        isLoading={isMutating}
        // disabled={isMutating}
        onClick={async () => {
          handleValidateEmail(email);
          handleValidateName(name);
          handleValidatePhone(phone);
          setTrySubmit(true);
          if (isValidEmail && isValidName && isValidPhone && recaptchaCheck) {
            await trigger().then((response) => {
              if (response.success) {
                deleteCookie('RESERVATION_DATA');
                router('/confirm');
              }
            });
          }
        }}
      >
        <Text>Rezerva</Text>
      </Button>
    </Flex>
  );
}

const dropDownStyle = {
  menuListStyles: {
    zIndex: 999,
    height: '180px',
    overflow: 'scroll',
  },
  menuButtonStyles: {
    borderRadius: '0.375rem',
    width: '100%',
    // maxWidth: '320px',
    height: '40px',
    border: '1px solid var(--chakra-colors-lightGrey2)',
    backgroundColor: 'white',
  },
};

const inputStyle = {
  borderColor: 'var(--chakra-colors-lightGrey2)',
  backgroundColor: 'white',
};

const gridWrapperStyle = {
  gap: { mobile: '1rem', sm: '2rem' },
  templateColumns: { mobile: 'auto', sm: '1fr 1fr' },
  mt: 'sm',
};

const formWrapperStyle = {
  flexDirection: 'column',
  textAlign: 'start',
  fontWeight: 'bold',
  color: 'darkGrey1',
  width: { mobile: '95%' },
  maxWidth: '650px',
} as FlexProps;

const errorMsgStyle = {
  fontSize: 'xs',
  color: 'error',
  ml: 'md',
  mt: 'sm',
};
