import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 4.266 4.266"
    color="teal"
    {...props}
  >
    <path
      stroke="teal"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.35}
      d="M3.734 1.244v-.71m0 0h-.711m.711 0L2.667 1.6m.623 2.134A2.755 2.755 0 0 1 .541.774C.547.696.55.657.57.623A.188.188 0 0 1 .648.551C.682.534.726.534.81.534h.502a.4.4 0 0 1 .138.012.177.177 0 0 1 .071.049c.022.025.033.058.058.127l.207.569c.028.079.042.118.04.154a.177.177 0 0 1-.032.09.411.411 0 0 1-.129.095l-.243.147a2.151 2.151 0 0 0 1.066 1.066l.145-.244c.042-.072.064-.108.095-.129a.177.177 0 0 1 .09-.032c.038-.003.076.012.154.04l.57.207a.403.403 0 0 1 .127.058.177.177 0 0 1 .049.071.4.4 0 0 1 .012.138v.502c0 .086 0 .128-.017.164a.188.188 0 0 1-.071.077c-.035.02-.073.023-.151.029a2.608 2.608 0 0 1-.204.008Z"
    />
  </svg>
);
const Memo = memo(SvgPhone);
export default Memo;
