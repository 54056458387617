import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 0.8 0.8"
    color="teal"
    {...props}
  >
    <path fill="none" d="M0 0h.8v.8H0z" />
    <path d="M.6.16h.12v.6H.08v-.6H.2V.12C.2.104.206.09.218.078.23.066.244.06.26.06s.031.006.042.018A.058.058 0 0 1 .32.12v.04h.16V.12C.48.104.486.09.498.078.51.066.524.06.54.06s.031.006.042.018A.058.058 0 0 1 .6.12v.04zM.24.12v.1a.02.02 0 0 0 .02.02.02.02 0 0 0 .02-.02v-.1A.018.018 0 0 0 .274.106C.27.102.266.1.26.1S.25.102.246.106A.018.018 0 0 0 .24.12zm.28 0v.1c0 .006.002.01.006.014S.535.24.54.24.55.238.554.234A.02.02 0 0 0 .56.22v-.1A.02.02 0 0 0 .54.1a.02.02 0 0 0-.02.02zm.16.6v-.4H.12v.4h.56zM.28.36v.08H.2V.36h.08zm.08 0h.08v.08H.36V.36zm.16.08V.36H.6v.08H.52zM.28.48v.08H.2V.48h.08zm.08 0h.08v.08H.36V.48zm.16.08V.48H.6v.08H.52zM.28.6v.08H.2V.6h.08zm.16.08H.36V.6h.08v.08zm.16 0H.52V.6H.6v.08z" />
  </svg>
);
const Memo = memo(SvgCalendar);
export default Memo;
