import { Flex } from '@chakra-ui/react';
import { CardWithDivider } from 'components/global';
import { ReservationSummary } from '../common';
import InsuranceWithGuaranty from './InsuranceWithGaranty';
import InsuranceWithoutGuaranty from './InsuranceWithoutGuaranty';

export function SelectInsurance() {
  return (
    <Flex flexDirection={{ mobile: 'column', lg: 'row' }} height={{ mobile: '100vh', lg: 'auto' }}>
      <ReservationSummary displayCarInformation variant="mobile" />
      <CardWithDivider title="Alege varianta de asigurare" {...cardWrapperStyle}>
        <Flex justifyContent="space-evenly" gap="2rem" flexWrap="wrap">
          <InsuranceWithGuaranty />
          <InsuranceWithoutGuaranty />
        </Flex>
      </CardWithDivider>
      <ReservationSummary displayCarInformation variant="desktop" />
    </Flex>
  );
}

const cardWrapperStyle = {
  alignSelf: 'flex-start',
  paddingX: 'sm',
  paddingY: '2xl',
  height: '100%',
  mt: 'sm',
};
