import { ProgressLayout } from '../components/layout';
import { SelectInsurance } from '../components/CreateReservation';

export function SelectInsurancePage() {
  return (
    <ProgressLayout progress="66.66">
      <SelectInsurance />
    </ProgressLayout>
  );
}
