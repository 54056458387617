import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCutie = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={32}
    height={32}
    viewBox="0 0 20.48 20.48"
    color="teal"
    {...props}
  >
    <path d="M17.92 15.506V4.974a2.56 2.56 0 1 0-1.706 0v4.413h-5.12V4.974a2.56 2.56 0 1 0-1.707 0v4.413h-5.12V4.974a2.56 2.56 0 1 0-1.706 0v5.266c0 .471.382.853.853.853h5.973v4.413a2.56 2.56 0 1 0 1.706 0v-4.413h5.12v4.413a2.56 2.56 0 1 0 1.706 0zm-.853-13.799a.852.852 0 1 1-.001 0zm-6.827 0a.852.852 0 1 1 .001 1.705.852.852 0 0 1-.001-1.705zm-6.827 0a.852.852 0 1 1 .001 1.705.852.852 0 0 1-.001-1.705zm6.827 17.066a.852.852 0 1 1-.001-1.705.852.852 0 0 1 .001 1.705zm6.827 0a.852.852 0 1 1-.001-1.705.852.852 0 0 1 .001 1.705z" />
  </svg>
);
const Memo = memo(SvgCutie);
export default Memo;
