import { Card } from '../../../global';
import { Button, Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import { CarInsurance } from '../../../common';
import { updateCookieValueWithExtraData, useReservationState } from 'utils';
import { useNavigate } from 'react-router-dom';

export default function InsuranceWithoutGuaranty() {
  const { reservationState, updateReservation } = useReservationState();

  const { reservationPeriodInDays } = reservationState;
  const { reservationPrice, insurancePerDay, price } = reservationState.car;
  let insuranceFullCasco = reservationPeriodInDays * insurancePerDay;
  const navigation = useNavigate();
  return (
    <Card {...insuranceCardWrapper}>
      <Flex justifyContent="center" alignItems="center" mb="sm">
        <CarInsurance />
        <Text ml="0.5rem" fontSize="xl" color="darkGrey1" fontWeight="bold">
          Inchiriaza fara garantie
        </Text>
      </Flex>
      <Text {...insuranceTextDetailsStyle}>Chirie: {price}€/zi</Text>
      <Text {...insuranceTextDetailsStyle}>Asigurare Full CASCO: {insuranceFullCasco}€</Text>
      <Text {...insuranceTextDetailsStyle}>
        Cost total: {reservationPrice + insuranceFullCasco}€
      </Text>
      <Text {...insuranceTextDetailsStyle}>Garantie: 0€</Text>
      <Button
        mt="sm"
        bgColor="teal"
        color="lightGrey5"
        width="100%"
        onClick={() => {
          updateCookieValueWithExtraData('RESERVATION_DATA', { insuranceWithGuaranty: false });
          updateReservation({ insuranceWithGuaranty: false });
          navigation('/check-out');
        }}
      >
        Reserva fara garantie
      </Button>
    </Card>
  );
}

const insuranceCardWrapper = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 'md',
  gap: '0.6rem',
  width: '290px',
} as FlexProps;

const insuranceTextDetailsStyle = {
  fontSize: 'md',
  color: 'darkGrey2',
  fontWeight: 'medium',
} as TextProps;
