import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCookie } from '../helpers';
import { useReservationState } from './ReservationContext';
import Fonts from '../../theme/components/Fonts';

// Creați un context pentru furnizarea datelor cookie-ului
const CookieContext = createContext(null);

// Hook personalizat pentru a accesa contextul
export function useCookie() {
  return useContext(CookieContext);
}

// Componenta Provider pentru gestionarea cookie-ului
export function CookieProvider({ children }: any) {
  const { updateReservation } = useReservationState();
  const [alreadyInitialize, setInitateData] = useState(false);

  // Funcție pentru a verifica dacă cookie-ul a expirat
  function initiateValueFromCookie(cookieName: any) {
    let cookieValue;
    try {
      cookieValue = JSON.parse(getCookie(cookieName) ?? '');
    } catch {}

    if (cookieValue && !alreadyInitialize) {
      const datePickUp = new Date(cookieValue.datePickUp);
      const hourPickUp = new Date(cookieValue.hourPickUp);

      const dateDropOff = new Date(cookieValue.dateDropOff);
      const hourDropOff = new Date(cookieValue.hourDropOff);

      updateReservation({
        datePickUp: datePickUp,
        hourPickUp: hourPickUp,
        dateDropOff: dateDropOff,
        hourDropOff: hourDropOff,
        pickUpLocation: cookieValue.pickUpLocation,
        dropOffLocation: cookieValue.dropOffLocation,
        reservationPeriodInDays: cookieValue.reservationPeriodInDays,
      });

      if (cookieValue?.car) {
        updateReservation({
          car: cookieValue.car,
        });
      }

      if (cookieValue?.insurance) {
        updateReservation({
          insurance: cookieValue.insurance,
        });
      }
      setInitateData(true);
    }
  }

  // Verificați starea cookie-ului la intervale regulate (de exemplu, la fiecare 5 minute)
  useEffect(() => {
    initiateValueFromCookie('RESERVATION_DATA');

    const intervalId = setInterval(
      () => {
        initiateValueFromCookie('RESERVATION_DATA');
      },
      60000,
      [alreadyInitialize]
    );

    return () => clearInterval(intervalId);
  }, [alreadyInitialize]);

  // Returnați furnizorul de context cu datele cookie-ului
  return (
    <CookieContext.Provider value={null}>
      <Fonts />
      {children}
    </CookieContext.Provider>
  );
}
