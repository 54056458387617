import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={9} fill="none" color="teal" {...props}>
    <path
      fill={props.color || '#58595B'}
      fillRule="evenodd"
      d="M1.53 8.531.47 7.471 7 .94l6.53 6.53-1.06 1.061L7 3.061l-5.47 5.47Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChevronUp);
export default Memo;
