import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPeople = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 40.96 40.96"
    color="teal"
    {...props}
  >
    <path d="m29.84 33.411-8.059-4.464c2.995-2.356 3.809-6.988 3.809-9.584v-5.405c0-3.579-4.755-7.565-9.532-7.565-4.775 0-9.656 3.987-9.656 7.565v5.405c0 2.361.999 7.137 4.025 9.564L2.16 33.411S0 34.374 0 35.574v3.244c0 1.194.969 2.163 2.16 2.163h27.68a2.163 2.163 0 0 0 2.161-2.163v-3.243c0-1.272-2.161-2.163-2.161-2.163zm-.399 5.009H2.56v-2.289c.184-.134.44-.289.643-.381.06-.028.12-.056.177-.089l8.268-4.484a2.558 2.558 0 0 0 .382-4.248c-2.147-1.721-3.066-5.533-3.066-7.566v-5.405c0-1.839 3.466-5.005 7.096-5.005 3.697 0 6.972 3.123 6.972 5.005v5.405c0 2.005-.623 5.834-2.831 7.571a2.564 2.564 0 0 0-.969 2.227 2.564 2.564 0 0 0 1.325 2.034l8.059 4.464c.071.039.161.081.236.114.189.08.421.214.591.332v2.315zm9.358-11.383-8.179-4.464c2.995-2.356 3.929-6.988 3.929-9.584V7.584c0-3.579-4.875-7.605-9.651-7.605-3.104 0-6.278 1.688-8.125 3.849 1.053.065 2.215.067 3.219.387a7.962 7.962 0 0 1 4.906-1.675c3.697 0 7.091 3.162 7.091 5.045v5.405c0 2.005-.742 5.834-2.951 7.571a2.564 2.564 0 0 0-.969 2.227 2.564 2.564 0 0 0 1.325 2.034l8.179 4.464c.071.039.161.081.236.114.189.08.421.214.591.332v2.276h-5.167c.78.589 1.016 1.431 1.319 2.56H38.8a2.163 2.163 0 0 0 2.161-2.163v-3.204c-.001-1.274-2.162-2.164-2.162-2.164z" />
  </svg>
);
const Memo = memo(SvgPeople);
export default Memo;
