import { PickUpForm, Services } from 'components';
// import {logo} from '../../../../build/images/senior-man-driving-while-another-man-is-sitting-car-roof-with-blackboard.jpg'
import { Card, CardBody, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { CardWithDivider } from 'components/global';

const textStyle = {
  p: { mobile: '0% 10%', lg: '0% 15%', xl: '0% 20%' },
};

const cardWithDividerChildren = (
  <Text {...textStyle}>
    Daca doresti sa intalnesti o echipa tanara si ambitioasa care incearca sa-si faca treaba cu
    placere atunci nu ezita si inchiriaza o masina de la Iasi si nu vei regreta. Vom incerca sa ne
    ridicam la inaltimea asteptarilor tale astfel incat sa revii cu placere la noi!
  </Text>
);

export default function PickUpPage() {
  return (
    <>
      <Flex flexDir="column">
        <Flex {...pageIntroStyle}>
          <Flex flexDir="column" justifyContent="center">
            <Heading as="h1" fontSize={{ sm: '7xl' }} color="teal">
              Rent a Car
            </Heading>
            <Heading as="h1" fontSize={{ sm: '3xxl' }} color="darkGrey1">
              Inchiriaza o Masina Simplu si Rapid
            </Heading>
          </Flex>
        </Flex>

        <Flex {...pickUpPageStyle}>
          <Flex>
            <PickUpForm />
          </Flex>

          <Card {...servicesDescriptionStyle}>
            <Heading
              as="h3"
              fontSize={{ mobile: 'lg', sm: 'lg' }}
              p={{ mobile: 0, sm: '1.25rem' }}
              color="darkGrey1"
            >
              Masini de inchiriat la preturi accesibile!
            </Heading>
            <CardBody p="0" mt="md">
              <Text fontSize={{ mobile: 'sm', sm: 'lg' }} color="darkGrey2">
                Compara preturile intre diferite categorii de masini si alege varianta optima pentru
                calatoria ta
              </Text>
            </CardBody>
          </Card>
        </Flex>
      </Flex>

      <CardWithDivider
        children={cardWithDividerChildren}
        title="De ce sa inchiriezi de la noi?"
        marginTop="3rem"
        paddingX="1rem"
      />

      <Services />
    </>
  );
}

const servicesDescriptionStyle = {
  width: { mobile: '100%', md: '30rem' },
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  height: 'fit-content',
  p: '2rem',
};

const pickUpPageStyle = {
  width: { lg: '100%' },
  flexDir: 'row',
  flexWrap: 'wrap',
  gap: { mobile: '3rem', lg: '3rem' },
  backgroundImage:
    'radial-gradient(circle,  rgba(51, 153, 153), rgba(0,128,128,1), rgba(0, 115, 115,1) )',
  justifyContent: 'center',
  alignItems: 'center',
  p: { mobile: '3rem 1rem', sm: '4rem', lg: '5rem', xl: '5rem 10rem' },
  borderRadius: { mobile: '0.5rem', sm: '1rem', lg: '5rem 0rem', xl: '4rem 0rem' },
} as FlexProps;

const pageIntroStyle = {
  flexDirection: 'column',
  mb: '1rem',
  display: { mobile: 'none', sm: 'flex' },
  alignItems: { sm: 'center', lg: 'start' },
  textAlign: { sm: 'center', lg: 'start' },
  p: '4rem 0rem 4rem 1rem',
  gap: '10px',
} as FlexProps;
