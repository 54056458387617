import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgDoor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    stroke={props.color || '#58595B'}
    className="door_svg__icon door_svg__line"
    data-name="Layer 1"
    viewBox="0 0 24 24"
    color="teal"
    {...props}
  >
    <path
      d="M12.29 3.29 20 11v4.13a1 1 0 0 1-.86 1l-2.06.3A6.11 6.11 0 0 0 12 21H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6.59a1 1 0 0 1 .7.29ZM20 11H4m4 4h2"
      style={{
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: 1.5,
      }}
    />
  </svg>
);
const Memo = memo(SvgDoor);
export default Memo;
