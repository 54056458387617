import { Flex, Text } from '@chakra-ui/react';
import { Bagaj, Cutie, Door, IdCard, People } from 'components/common';

interface CarDetailsProps {
  bags: any;
  doors: any;
  gearbox: any;
  minAge: any;
  numberSeats: any;
}

export default function CarDetails({ bags, doors, gearbox, minAge, numberSeats }: CarDetailsProps) {
  return (
    <>
      <Flex flexDir="row" gap="1.2rem" mt="lg">
        <Flex {...containerCarDetailsStyle}>
          <People {...iconStyle(16)} /> <Text>{numberSeats}</Text>
        </Flex>
        <Flex {...containerCarDetailsStyle}>
          <Door {...iconStyle(18)} /> <Text>{doors}</Text>
        </Flex>
        <Flex {...containerCarDetailsStyle}>
          <Bagaj {...iconStyle(18)} fill="none" /> <Text>{bags}</Text>
        </Flex>
        <Flex {...containerCarDetailsStyle}>
          <Cutie {...iconStyle(16)} />
          <Text>{gearbox == 'manual' ? 'M' : 'A'}</Text>
        </Flex>
        <Flex {...containerCarDetailsStyle}>
          <IdCard {...iconStyle(18)} />
          <Text>{minAge}</Text>
        </Flex>
      </Flex>
    </>
  );
}

const containerCarDetailsStyle = {
  alignItems: 'center',
  gap: '5px',
};

const iconStyle = (iconSize: number) => ({
  width: iconSize,
  height: iconSize,
  fill: 'gray',
  color: 'gray',
});
