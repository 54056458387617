import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Grid,
  GridProps,
  Heading,
  HeadingProps,
  Image,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Card } from 'components';
import { Car } from 'types';
import { Ceasuri } from 'components/common';
import CarDetails from './CarDetails';

interface Props extends Car {
  handleClick: () => void;
}

export default function CarItem({
  bags,
  category,
  doors,
  gearbox,
  image,
  minAge,
  price,
  numberSeats,
  name,
  reservationPrice,
  handleClick,
}: Props) {
  return (
    <Card width="100%" mb="md">
      <Grid {...wrapperCarItemStyle}>
        <Box {...containerImageStyle}>
          <Image
            src={image}
            alt={name}
            m="auto"
            h={{ mobile: 'auto', xs: '100%' }}
            loading="lazy"
          />
        </Box>
        <Flex
          flexDir="column"
          pl={{ mobile: 0, md: 'lg' }}
          pr={{ mobile: 0, md: '3xl' }}
          justifyContent="space-evenly"
        >
          <Flex flexDir="column">
            <Heading as="h4" size="lg" color="darkGrey1" textDecoration="wr">
              {name}
            </Heading>
            <Box
              border="1px solid var(--chakra-colors-darkGrey4)"
              width="fit-content"
              borderRadius="15px"
              mt="sm"
            >
              <Text {...categoryStyle}>sau {category} similar</Text>
            </Box>
          </Flex>
          <CarDetails
            bags={bags}
            doors={doors}
            gearbox={gearbox}
            minAge={minAge}
            numberSeats={numberSeats}
          />

          <Flex justifyContent="flex-start" gap={2} mt="xmd">
            <Ceasuri {...iconStyle(18)} />
            <Text fontSize="sm">Kilometraj nelimitat</Text>
          </Flex>
        </Flex>
        <Box py="md">
          <Box {...delimiterStyle} />
        </Box>
        <Flex {...priceButtonContainerStyle}>
          <Flex flexDir="column" alignItems={'flex-end'}>
            <Heading {...priceWrapperStyle}> de la </Heading>
            <Heading {...pricePerDayStyle}>{price}€/zi</Heading>
            <Heading {...totalPriceStyle}>Total {reservationPrice}€</Heading>
          </Flex>

          <Button
            bgColor="teal"
            color="lightGrey5"
            width={{ mobile: '100%', sm: 'auto' }}
            onClick={() => handleClick()}
          >
            Selecteaza
          </Button>
        </Flex>
      </Grid>
    </Card>
  );
}

const categoryStyle = {
  py: 'xs',
  px: 'sm',
  color: 'darkGrey2',
  fontSize: 's',
  fontWeight: 'bold',
  textTransform: 'uppercase',
} as TextProps;

const priceWrapperStyle = {
  as: 'h5',
  color: 'darkGrey2',
  fontSize: 's',
  textTransform: 'uppercase',
} as HeadingProps;

const pricePerDayStyle = {
  as: 'h2',
  size: 'lg',
  color: 'darkGrey1',
} as HeadingProps;

const totalPriceStyle = {
  as: 'h3',
  size: 'xs',
  color: 'darkGrey2',
  fontWeight: 'semibold',
  textTransform: 'uppercase',
} as HeadingProps;

const iconStyle = (iconSize: number) => ({
  width: iconSize,
  height: iconSize,
  fill: 'gray',
  color: 'gray',
});

const priceButtonContainerStyle = {
  flexDir: 'column',
  justifyContent: 'space-around',
  pl: { mobile: 0, md: 'md' },
  gap: { mobile: '30px', lg: '0' },
} as FlexProps;

// const containerCarDetailsStyle = {
//   alignItems: 'center',
//   fontSize: 'xs',
//   gap: '5px',
//   fontWeight: 'semibold',
// };

const delimiterStyle = {
  w: { mobile: 'full', md: '1px' },
  h: { mobile: '1px', md: 'full' },
  bgColor: 'var(--chakra-colors-lightGrey3)',
  alignSelf: 'center',
} as BoxProps;

const wrapperCarItemStyle = {
  templateColumns: { mobile: 'auto', lg: '1fr 1fr auto 1fr' },
  templateRows: { mobile: '1fr auto auto auto', md: 'auto ' },
  width: '100%',
} as GridProps;

const containerImageStyle = {
  w: { mobile: '100%' },
  // h: { mobile: '8.75rem', xs: '10.938rem', sm: '17.875rem', md: '13.25rem' },
  h: '200px',
  pos: 'relative',
} as BoxProps;
