import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={9} fill="none" color="teal" {...props}>
    <path
      fill={props.color || '#58595B'}
      fillRule="evenodd"
      d="m12.47.47 1.06 1.06L7 8.06.47 1.53 1.53.47 7 5.94 12.47.47Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChevronDown);
export default Memo;
