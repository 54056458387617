import { Flex, Grid } from '@chakra-ui/react';
import { mockCars } from './mockCars';
import { ReservationSummary } from '../common';
import { CarItem } from './CarItem';
import { useMemo } from 'react';
import { enhancementCarListing, updateCookieValueWithExtraData, useReservationState } from 'utils';
import type { Car } from 'types';
import { useNavigate } from 'react-router-dom';

export function SelectCar() {
  const { reservationState, updateReservation } = useReservationState();
  const { reservationPeriodInDays } = reservationState;
  const navigation = useNavigate();

  const carsList = useMemo(() => {
    return enhancementCarListing(mockCars as any, reservationPeriodInDays);
  }, [reservationPeriodInDays]);

  return (
    <Grid
      gridTemplateColumns={{ lg: 'auto 1fr' }}
      flexDir="row"
      justifyContent="space-evenly"
      alignItems="start"
      gap="1rem"
      maxWidth="1300px"
      flexWrap="wrap"
      margin={{ md: 'auto' }}
      padding={{ mobile: 'sm' }}
    >
      <ReservationSummary variant="mobile" />

      <Flex flexWrap="wrap" gap="1rem" width="100%">
        {carsList.map((car: Car, index: number) => (
          <CarItem
            {...car}
            key={index}
            handleClick={() => {
              updateCookieValueWithExtraData('RESERVATION_DATA', { car: car });
              updateReservation({ car: car });
              navigation('/select-insurance');
            }}
          />
        ))}
      </Flex>
      <ReservationSummary variant="desktop" />
    </Grid>
  );
}
