import { SelectCar } from 'components';
import { ProgressLayout } from '../components/layout';

export function SelectCarPage() {
  return (
    <ProgressLayout progress="33.33">
      <SelectCar />
    </ProgressLayout>
  );
}
