import { ReservationState } from '../context/ReservationContext';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';

export function createReservation(reservationState: ReservationState) {
  return useSWRMutation('create reservation', () =>
    fetch(`${process.env.REACT_APP_NETWORK_API_DOMAIN}/send-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reservationState),
    })
      .then((response) => response.json())
      .catch((error) => {
        toast.error('Rezervarea nu s-a putut realiza, va rugam sa ne contactati telefonic', {
          position: 'bottom-right',
          duration: 5000,
          style: { backgroundColor: 'red', fontWeight: 'bold' },
          icon: null,
        });
        console.error('Eroare la trimiterea reCAPTCHA către server: ' + error);
      })
  );
}
