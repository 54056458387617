/* theme.ts */
import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { typography } from './foundations/typography';
import { breakpoints } from './foundations/breakpoints';
import { space } from './foundations/space';
import Button from './components/Button';

export const theme = extendTheme({
  breakpoints,
  space,
  colors,
  fonts: typography.fonts,
  fontSizes: typography.fontSizes,
  lineHeights: typography.lineHeights,
  initialColorMode: colors,
  components: {
    Button,
  },
});
