import { Box, Button, Flex, FlexProps, Text } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, differenceInDays } from 'date-fns';
import { calculateDiffInDays, setCookie, useReservationState } from 'utils';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { Calendar, Pin } from '../../common';
import { Dropdown } from '../../global';

export default function PickUpForm() {
  const { reservationState, updateReservation } = useReservationState();

  const { pickUpLocation, dateDropOff, dropOffLocation, datePickUp, hourPickUp, hourDropOff } =
    reservationState;

  const navigate = useNavigate();

  const handlePickUpLocation = (event: any) => {
    updateReservation({ pickUpLocation: event });
  };

  const handleDropOffLocation = (event: any) => {
    updateReservation({ dropOffLocation: event.target.value });
  };

  const handleHourPick = (date: any) => {
    updateReservation({ hourPickUp: date });
  };

  const handleHourDrop = (date: any) => {
    updateReservation({ hourDropOff: date });
  };

  const handleDatePick = (date: Date) => {
    updateReservation({ datePickUp: date });
    if (differenceInDays(dateDropOff, date) <= 0) {
      updateReservation({ dateDropOff: addDays(date, 1) });
    }
  };

  const handleDateDrop = (date: any) => {
    updateReservation({ dateDropOff: date });
  };

  return (
    <Flex {...pickUpFormStyle}>
      <Flex alignItems="center">
        <Flex {...locationLabelStyle}>
          <Pin {...iconStyle} />
          <Text whiteSpace="nowrap">Pick-up</Text>
        </Flex>
        <Dropdown
          selectedId={pickUpLocation}
          matchWidth
          options={[{ code: 'Iasi', label: 'Iasi', id: 'Iasi' }]}
          onChange={(value) => {
            if (value) handlePickUpLocation(value.code);
          }}
          dropdownStyles={dropDownStyle}
        />
      </Flex>
      <Flex alignItems="center" mt="1rem">
        <Flex {...locationLabelStyle}>
          <Pin {...iconStyle} />
          <Text whiteSpace="nowrap">Drop-off</Text>
        </Flex>

        <Dropdown
          selectedId={dropOffLocation}
          matchWidth
          options={[{ code: 'Iasi', label: 'Iasi', id: 'Iasi' }]}
          onChange={(value) => {
            if (value) handleDropOffLocation(value.code);
          }}
          dropdownStyles={dropDownStyle}
        />
      </Flex>
      <Flex>
        <Flex {...dropDownPickerDateStyle}>
          <Flex {...locationLabelStyle}>
            <Calendar {...iconStyle} />
            <Text>Pick-up</Text>
          </Flex>
          <DatePicker
            selected={datePickUp}
            onChange={handleDatePick}
            selectsStart
            minDate={addDays(new Date(), 1)}
            startDate={datePickUp as Date}
            endDate={dateDropOff}
            dateFormat="dd-MM-yyyy"
            timeFormat="dd-MM-yyyy"
          />
        </Flex>
        <Box {...dropDownPickerHourStyle}>
          <DatePicker
            selected={hourPickUp as Date}
            onChange={handleHourPick}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </Box>
      </Flex>
      <Flex>
        <Flex {...dropDownPickerDateStyle}>
          <Flex {...locationLabelStyle}>
            <Calendar {...iconStyle} />
            <Text whiteSpace="nowrap">Drop-off</Text>
          </Flex>
          <DatePicker
            selected={dateDropOff}
            onChange={handleDateDrop}
            selectsEnd
            minDate={addDays(datePickUp, 1)}
            startDate={datePickUp}
            endDate={dateDropOff}
            dateFormat="dd-MM-yyyy"
            timeFormat="dd-MM-yyyy"
          />
        </Flex>
        <Box {...dropDownPickerHourStyle}>
          <DatePicker
            selected={hourDropOff as Date}
            onChange={handleHourDrop}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </Box>
      </Flex>
      <Button
        {...submitButtonStyle}
        onClick={() => {
          const step1 = {
            datePickUp: datePickUp,
            hourPickUp: hourPickUp,
            dateDropOff: dateDropOff,
            hourDropOff: hourDropOff,
            pickUpLocation,
            dropOffLocation,
            reservationPeriodInDays: calculateDiffInDays(
              datePickUp,
              hourPickUp as Date,
              dateDropOff,
              hourDropOff as Date
            ),
          };

          setCookie('RESERVATION_DATA', JSON.stringify(step1), 30, '/');
          updateReservation({ ...step1 });
          navigate('/select-car');
        }}
      >
        Trimite
      </Button>
    </Flex>
  );
}

const dropDownStyle = {
  menuListStyles: {
    zIndex: 999,
    height: '180px',
    width: '50px',
    overflow: 'scroll',
    minWidth: '100%',
  },
  menuButtonStyles: {
    borderRadius: '0px 5px 5px 0px',
    width: '100%',
    height: '36px',
    border: '1px solid var(--chakra-colors-lightGrey3)',
    backgroundColor: 'white',
  },
  labelStyles: {
    color: 'teal',
    _hover: {
      color: 'teal',
    },
  },
};

const pickUpFormStyle = {
  // width: { mobile: '100%', lg: '24rem' },
  width: { mobile: '100%', lg: '100%' },
  flexDir: 'column',
  bgColor: 'white',
  height: 'fit-content',
  p: { mobile: 'md', lg: '2rem' },
  borderRadius: '5px',
} as FlexProps;

const dropDownPickerHourStyle = {
  width: { mobile: '28%', lg: '6.5rem' },
  border: '1px solid #E2E8F0',
  borderRadius: '5px',
  alignSelf: 'center',
  mt: '1rem',
  pl: '5px',
  fontSize: '14px',
  color: 'teal',
};

const dropDownPickerDateStyle = {
  // width: { mobile: '11rem', xs:'13.5rem', lg: '12rem' },
  width: { md: '70%' },
  border: '1px solid #E2E8F0',
  mt: '1rem',
  borderRadius: '0px 5px 5px 0px',
  fontSize: '14px',
  color: 'teal',
};

const locationLabelStyle = {
  width: { mobile: '6.5rem', lg: '7rem' },
  minWidth: '6.5rem',
  bgColor: 'lightGrey4',
  fontWeight: 'bold',
  color: 'darkGrey2',
  fontSize: { mobile: 'sm', lg: 'md' },
  p: '6px 12px',
  whiteSpace: 'nowrap',
  lineHeight: '3',
  textAlign: 'left',
  alignItems: 'center',
  gap: { mobile: '3px', lg: '5px' },
} as FlexProps;

const submitButtonStyle = {
  width: '15rem',
  mt: '1.5rem',
  bgColor: 'teal',
  color: 'white',
  alignSelf: 'center',
};

const iconStyle = {
  width: '16px',
  height: '16px',
  color: '#58595B',
  fill: '#58595B',
};
