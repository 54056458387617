import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSuccess24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    color="teal"
    {...props}
  >
    <path
      fill={props.color || '#1C8754'}
      fillRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5Zm6.796 5.955c.399.399.435 1.024.108 1.464l-.108.127L9.75 18.09l-4.545-4.546a1.125 1.125 0 0 1 1.464-1.7l.127.11 2.954 2.954 7.454-7.455c.44-.439 1.152-.439 1.591 0Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSuccess24);
export default Memo;
