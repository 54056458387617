import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Logo, Whatsapp } from '../svg';
import { deleteCookie } from '../../../utils';

export default function HeaderDesktop() {
  return (
    <Flex
      display={{ mobile: 'none', md: 'flex' }}
      my="md"
      justifyContent="space-between"
      width="100%"
    >
      <Link
        href="/"
        onClick={() => {
          deleteCookie('RESERVATION_DATA');
        }}
      >
        <Box marginLeft={'md'}>
          <Logo width="140px" />
        </Box>
      </Link>

      <Flex flexDir="row" alignItems="center" justifyContent="center" marginRight="md">
        <Text color="darkGrey1" fontWeight="semibold">
          Telefon rezervari: +40750171847
        </Text>
        <Link href="https://wa.me/+40750171847" target="_blank" marginLeft="xs">
          <Whatsapp width="26px" height="26px" />
        </Link>
      </Flex>
    </Flex>
  );
}
