export function checkRecaptcha(value: string, setRecaptchaCheckState: (val: boolean) => void) {
  // Trimiteți valoarea reCAPTCHA și alte informații către server pentru validare
  fetch(`${process.env.REACT_APP_NETWORK_API_DOMAIN}/verify-recaptcha`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ recaptchaValue: value }),
  })
    .then((response) => response.json())
    .then((data) => {
      // Verificați răspunsul de la server
      if (data.success) {
        setRecaptchaCheckState(true);
      } else {
        setRecaptchaCheckState(false);
      }
    })
    .catch((error) => {
      console.error('Eroare la trimiterea reCAPTCHA către server: ' + error);
    });
}
