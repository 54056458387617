import { Box, BoxProps, Image } from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface Props extends BoxProps {
  svg?: ReactElement;
  src?: string;
}

export default function Icon({ svg, src, ...otherProps }: Props) {
  if (src) {
    return <Image src={src} {...otherProps} data-testid="svg-container" />;
  }

  return (
    <Box {...otherProps} data-testid="svg-container">
      {svg}
    </Box>
  );
}
