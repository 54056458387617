import { DefaultLayout } from '../components/layout';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { Button } from '../components/global';
import { useNavigate } from 'react-router-dom';

export default function ConfirmPage() {
  const navigation = useNavigate();
  return (
    <DefaultLayout>
      <HStack height="65%" textAlign="center">
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          <Text fontSize="lg" color="darkGrey2">
            Rezervarea s-a creat cu succes, in scurt timp vei primi un email cu datele rezervarii
            tale.
          </Text>
          <Flex mt="sm">
            <Button size="sm" variant="primary" fontSize="sm" onClick={() => navigation('/')}>
              Revina la pagina principala
            </Button>
          </Flex>
        </Flex>
      </HStack>
    </DefaultLayout>
  );
}
