import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderProps,
  Flex,
  FlexProps,
  Heading,
  Text,
} from '@chakra-ui/react';
import { CarInsurance, Phone, RoutePlan } from 'components/common';

type Service = {
  icon: JSX.Element; // Poate fi de tipul exact al iconului pe care îl folosești (de exemplu: React.ReactElement)
  title: string;
  description: string;
};

export default function Services() {
  let services: Service[] = [
    {
      icon: <Phone />,
      title: 'Rezervari la telefon',
      description:
        'View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.',
    },
    {
      icon: <RoutePlan />,
      title: 'Rute',
      description:
        'View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.',
    },
    {
      icon: <CarInsurance />,
      title: 'CASCO',
      description:
        'View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.View a summary of all your customers over the last month.',
    },
  ];

  return (
    <>
      <Flex {...servicesPageStyle}>
        <Heading as="h1" fontSize="3xxl" p={{ mobile: 0, sm: '1.25rem' }}>
          Serviciile noatre
        </Heading>
        <Flex {...serviceContainerStyle}>
          {services.map((service, index) => {
            return (
              <Card key={index}>
                <CardHeader {...headerAndIconStyle}>
                  {service.icon}
                  <Heading size="md"> {service.title}</Heading>
                </CardHeader>
                <CardBody pt={0}>
                  <Text>{service.description}</Text>
                </CardBody>
              </Card>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
}

const servicesPageStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  height: 'fit-content',
  p: '4rem 1rem',
  gap: '1.5rem',
  textAlign: 'center',
} as FlexProps;

const serviceContainerStyle = {
  width: { mobile: '100%', md: '80%' },
  display: 'flex',
  flexDirection: { mobile: 'column', lg: 'row' },
  gap: { mobile: '2rem' },
} as FlexProps;

const headerAndIconStyle = {
  p: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: { mobile: 'center', lg: 'stretch' },
  gap: { mobile: '1rem', lg: '0' },
} as CardHeaderProps;
