import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgIdCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 0.6 0.6"
    color="teal"
    {...props}
  >
    <path
      fill={props.color || '#000'}
      d="M.08.5v.02h.04V.5H.08Zm.2 0v.02h.04V.5H.28ZM.12.5V.48H.08V.5h.04ZM.28.48V.5h.04V.48H.28ZM.2.4a.08.08 0 0 1 .08.08h.04A.12.12 0 0 0 .2.36V.4ZM.12.48A.08.08 0 0 1 .2.4V.36a.12.12 0 0 0-.12.12h.04ZM.2.16a.08.08 0 0 0-.08.08h.04A.04.04 0 0 1 .2.2V.16Zm.08.08A.08.08 0 0 0 .2.16V.2a.04.04 0 0 1 .04.04h.04ZM.2.32A.08.08 0 0 0 .28.24H.24A.04.04 0 0 1 .2.28v.04Zm0-.04A.04.04 0 0 1 .16.24H.12A.08.08 0 0 0 .2.32V.28ZM.06.12h.48V.08H.06v.04Zm.5.02v.32H.6V.14H.56ZM.54.48H.06v.04h.48V.48ZM.04.46V.14H0v.32h.04Zm.02.02A.02.02 0 0 1 .04.46H0a.06.06 0 0 0 .06.06V.48Zm.5-.02a.02.02 0 0 1-.02.02v.04A.06.06 0 0 0 .6.46H.56ZM.54.12a.02.02 0 0 1 .02.02H.6A.06.06 0 0 0 .54.08v.04ZM.06.08A.06.06 0 0 0 0 .14h.04A.02.02 0 0 1 .06.12V.08Zm.3.16h.12V.2H.36v.04Zm0 .12h.12V.32H.36v.04Z"
    />
  </svg>
);
const Memo = memo(SvgIdCard);
export default Memo;
