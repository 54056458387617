import { ReservationSummaryDesktop } from './ReservationSummaryDesktop';
import { ReservationSummaryMobile } from './ReservationSummaryMobile';
import { formatDate, useReservationState } from '../../../../utils';
import { useMemo } from 'react';

interface Props {
  variant: 'desktop' | 'mobile';
  displayCarInformation?: boolean;
  displayInsuranceInfo?: boolean;
}
export function ReservationSummary({
  variant,
  displayCarInformation,
  displayInsuranceInfo,
}: Props) {
  const { reservationState } = useReservationState();

  const {
    car,
    pickUpLocation,
    dropOffLocation,
    hourDropOff,
    hourPickUp,
    datePickUp,
    dateDropOff,
    reservationPeriodInDays,
    insuranceWithGuaranty,
  } = reservationState;
  const datePickUpFormat = useMemo(() => formatDate(datePickUp, 'dd-MM-yyyy'), [datePickUp]);
  const hourPickUpFormat = useMemo(() => formatDate(hourPickUp, 'HH:mm'), [hourPickUp]);
  const dateDropOffFormat = useMemo(() => formatDate(dateDropOff, 'dd-MM-yyyy'), [dateDropOff]);
  const hourDropOffFormat = useMemo(() => formatDate(hourDropOff, 'HH:mm'), [hourDropOff]);
  const reservationPeriodLabel = useMemo(
    () => (reservationPeriodInDays > 0 ? 'zile' : 'zi'),
    [reservationPeriodInDays]
  );

  switch (variant) {
    case 'desktop': {
      return (
        <ReservationSummaryDesktop
          hourDropOffFormat={hourDropOffFormat}
          insuranceWithGuaranty={insuranceWithGuaranty}
          reservationPeriodInDays={reservationPeriodInDays}
          car={car}
          dateDropOffFormat={dateDropOffFormat}
          datePickUpFormat={datePickUpFormat}
          dropOffLocation={dropOffLocation}
          hourPickUpFormat={hourDropOffFormat}
          pickUpLocation={pickUpLocation}
          reservationPeriodLabel={reservationPeriodLabel}
          displayCarInformation={displayCarInformation}
          displayInsuranceInfo={displayInsuranceInfo}
        />
      );
    }
    case 'mobile': {
      return (
        <ReservationSummaryMobile
          hourDropOffFormat={hourDropOffFormat}
          insuranceWithGuaranty={insuranceWithGuaranty}
          reservationPeriodInDays={reservationPeriodInDays}
          car={car}
          dateDropOffFormat={dateDropOffFormat}
          datePickUpFormat={datePickUpFormat}
          dropOffLocation={dropOffLocation}
          hourPickUpFormat={hourDropOffFormat}
          pickUpLocation={pickUpLocation}
          reservationPeriodLabel={reservationPeriodLabel}
          displayCarInformation={displayCarInformation}
          displayInsuranceInfo={displayInsuranceInfo}
        />
      );
    }
  }
}
