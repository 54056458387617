import {
  Alert as AlertWrapper,
  BoxProps,
  Flex,
  Text,
  Tooltip as TooltipComponent,
  TooltipProps,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

import Icon from '../Icon';

interface Props extends TooltipProps {
  title?: string;
  description: string;
  variant: string;
  children: ReactNode;
  svg?: ReactElement;
  alertElementStyles?: BoxProps;
}
export default function Tooltip({ alertElementStyles, children, ...p }: Props) {
  return (
    <TooltipComponent
      label={
        <AlertWrapper background="transparent" paddingLeft={0} {...alertElementStyles}>
          {p.svg && <Icon svg={p.svg} mt={p.title && '-20px'} />}
          <Flex direction="column" justifyContent="space-evenly">
            {p.title !== 'undefined' && (
              <Text ml="sm" fontWeight="semibold">
                {p.title}
              </Text>
            )}
            <Text ml="sm">{p.description}</Text>
          </Flex>
        </AlertWrapper>
      }
      {...p}
    >
      {children}
    </TooltipComponent>
  );
}
