import { Header } from 'components';
import { VStack } from '@chakra-ui/react';

export default function DefaultLayout({ children }: any) {
  return (
    <VStack minH="100vh" height="100vh">
      <Header />
      {children}
    </VStack>
  );
}
