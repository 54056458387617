import { PickUpPage } from 'components';
import { DefaultLayout } from '../components/layout';

export function HomePage() {
  return (
    <DefaultLayout>
      <PickUpPage />
    </DefaultLayout>
  );
}
