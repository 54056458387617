import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { formatDate } from '../../../../utils';

import type { Props } from './ReservationSummaryDesktop';
import { parse } from 'date-fns';
import insuranceWithoutGuaranty from '../../SelectInsurance/InsuranceWithoutGuaranty';

export function ReservationSummaryMobile({
  displayInsuranceInfo,
  displayCarInformation,
  insuranceWithGuaranty,
  car,
  hourDropOffFormat,
  dateDropOffFormat,
  hourPickUpFormat,
  datePickUpFormat,
  reservationPeriodInDays,
  reservationPeriodLabel,
}: Props) {
  return (
    <Flex
      display={{ mobile: 'flex', lg: 'none' }}
      flexDirection="column"
      {...summaryContainerStyle}
    >
      {readerSummary()}
    </Flex>
  );

  function readerSummary() {
    const pickUpDate = parse(datePickUpFormat, 'dd-MM-yyyy', new Date());
    const dropOffDate = parse(dateDropOffFormat, 'dd-MM-yyyy', new Date());

    const formatPickUpDate = formatDate(pickUpDate, 'dd MMM');
    const formatDropOffDate = formatDate(dropOffDate, 'dd MMM');
    return (
      <Flex>
        <Text>
          {car && displayCarInformation ? `${car.name},` : ''} {reservationPeriodInDays}{' '}
          {reservationPeriodLabel} | {formatPickUpDate} {hourPickUpFormat}
          {'  '} - {formatDropOffDate} {hourDropOffFormat}{' '}
          {car && displayCarInformation
            ? ` | €
          ${
            car.reservationPrice +
            (insuranceWithGuaranty && displayInsuranceInfo ? car.insuranceGuaranty : 0) +
            (!insuranceWithGuaranty && displayInsuranceInfo
              ? car.insurancePerDay * reservationPeriodInDays
              : 0)
          }`
            : ''}
        </Text>
      </Flex>
    );
  }
}
const summaryContainerStyle = {
  alignItems: 'center',
  justifyContent: { mobile: 'space-between', sm: 'center' },
  fontWeight: 'semibold',
  fontSize: 'sm',
  color: 'darkGrey2',
  p: 'xs',
} as FlexProps;
