import { Flex, FlexProps } from '@chakra-ui/react';
import { CardWithDivider } from 'components/global';
import { ReservationSummary } from '../common';
import { CheckoutForm } from './CheckOutForm';
import React from 'react';
import { Toaster } from 'react-hot-toast';

export function CheckOut() {
  return (
    <Flex {...checkOutPageStyle}>
      <ReservationSummary variant="mobile" displayCarInformation displayInsuranceInfo />

      <CardWithDivider title="Informatii personale" {...cardWrapperStyle}>
        <CheckoutForm />
      </CardWithDivider>
      <Toaster />
      <ReservationSummary displayCarInformation displayInsuranceInfo variant="desktop" />
    </Flex>
  );
}

const cardWrapperStyle = {
  alignSelf: 'flex-start',
  paddingX: 'sm',
  paddingY: '2xl',
  height: '100%',
};

const checkOutPageStyle = {
  display: 'flex',
  width: '100%',
  height: 'auto',
  flexDirection: { mobile: 'column', lg: 'row' },
} as FlexProps;
