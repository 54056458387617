export const colors = {
  primary: '#00798e',
  secondary: '#0178a5',
  tertiary: '#511E62',
  baseBlack: '#000000',
  darkGrey1: '#333333',
  darkGrey2: '#58595B',
  darkGrey3: '#511E62',
  darkGrey4: '#716d6e',
  lightGrey1: '#979797',
  lightGrey2: '#CCCCCC',
  lightGrey3: '#DDDDDD',
  lightGrey4: '#E0E0E0',
  lightGrey5: '#F8f8f8',
  baseWhite: '#FFFFFF',
  fadedBaseWhite: '#FFFFFF00',
  alert: '#D73D00',
  error: '#d90941',
  info: '#007fab',
  success: '#1C8754',
  accessible: '#007FAB',
  tooltipAlert: '#FEEFD9',
  tooltipError: '#FBE6EC',
  tooltipInfo: '#e5f2f6',
  tooltipSuccess: '#E8F3ED',
  hubPrimary: '#BDD500',
  hubSecondary: '#7A3E98',
  zipPrimary: '#FC0F42',
  zipSecondary: '#1900ff',
  overlay: '#000000', // 50% opacity;
  alertTint: '#FEEFD9',
  errorTint: '#FBE6EC',
  infoTint: '#e5f2f6',
  successTint: '#e8f3ed',
  alertBorderTint: '#D73D0066',
  errorBorderTint: '#D9094166',
  infoBorderTint: '#007FAB66',
  successBorderTint: '#1C875466',
  infoBorder: '#007FAB',
  btnPrimaryHoverBg: '#008FA8',
  btnPrimaryFocusBoxShadow: '#00A5C2',
  btnSecondaryEnabled: '#511E62',
  btnSecondaryHoverBg: '#65257A',
  btnSecondaryFocusBoxShadow: '#7A2D94',
  discountApplied: '#1C8754',

  // badges
  lightPurple: '#6C3072',
  darkPink: '#AC467A',
  maroon: '#73325B',
  blue: '#0178A5',

  // Datepicker
  rangeSelection: '#BFDDE3',

  babyBlue: '#83aae0',
  deepBlueTitle: '#335193',
};
