import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { Logo, Whatsapp } from '../svg';
import { deleteCookie } from 'utils';

export default function HeaderMobile() {
  return (
    <Flex
      display={{ mobile: 'flex', md: 'none' }}
      my="md"
      justifyContent="space-between"
      width="100%"
    >
      <Link
        href="/"
        onClick={() => {
          deleteCookie('RESERVATION_DATA');
        }}
      >
        <Box marginLeft={'xs'}>
          <Logo width="140px" />
        </Box>
      </Link>

      <Flex flexDir="row" alignItems="center" justifyContent="center" marginRight="xs">
        <Flex color="darkGrey1" fontWeight="semibold" fontSize="xxs" flexDirection="column">
          <Text>Telefon rezervari:</Text>
          <Text>+40750171847</Text>
        </Flex>
        <Link href="https://wa.me/+40750171847" target="_blank" marginLeft="xs">
          <Whatsapp width="32px" height="32px" />
        </Link>
      </Flex>
    </Flex>
  );
}
