import {
  Box,
  BoxProps,
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuProps,
  Text,
  TextProps,
  useOutsideClick,
} from '@chakra-ui/react';
import { JSXElementConstructor, memo, ReactNode, useEffect, useRef, useState } from 'react';

import { ChevronDown, ChevronUp, Error24, Success24 } from 'components/common';
import Icon from '../Icon';

export type DropdownOption = {
  id: number | string;
  icon?: ReactNode;
  label: string | number;
  code?: string;
};

interface dropdownStyles {
  menuStyles?: MenuProps;
  menuButtonStyles?: MenuButtonProps;
  menuButtonWrapperStyles?: BoxProps;
  wrapperStyles?: BoxProps;
  menuListStyles?: BoxProps;
  errorHoverMenuButtonStyles?: BoxProps;
  labelStyles?: TextProps;
}

export interface DropdownProps {
  variant?: 'default' | 'error';
  dropdownStyles?: dropdownStyles;
  placeholder?: string;
  label?: string;
  icon?: ReactNode;
  hasError?: boolean;
  disabled?: boolean;
  skipChevron?: boolean;
  showStatusIcon?: boolean;
  children?: JSXElementConstructor<any>;
  onChange?: (option: DropdownOption | undefined) => void;
  options?: DropdownOption[];
  isOpenMenu?: boolean | undefined;
  matchWidth?: boolean;
  dataTestId?: string;
  selectedId?: string;
  onDisplayContent?: (param: boolean) => void;
  onBlur?: () => void;
}

function Dropdown(props: DropdownProps) {
  const {
    variant = 'default',
    options = [],
    children: CustomContent,
    dropdownStyles,
    isOpenMenu = false,
    matchWidth,
    dataTestId,
    hasError,
    disabled,
    showStatusIcon,
    selectedId,
    onDisplayContent,
    onBlur,
  } = props;
  const baseDataTestId = 'DropdownComp';
  const [selectedValue, setSelectedValue] = useState<DropdownOption | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const displayIcon = showStatusIcon && !disabled;
  const isValid = !hasError && selectedValue;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpenMenu !== isOpen) {
      setIsOpen(isOpenMenu);
    }
  }, [isOpenMenu]);
  useOutsideClick({
    ref: ref,
    handler: () => {
      setIsOpen(false);
      onDisplayContent?.(false);
      if (isOpen) {
        onBlur?.();
      }
    },
  });
  useEffect(() => {
    if (selectedId) {
      const o = getOptionById(selectedId);
      setSelectedValue(o);
    }
  }, [selectedId, options]);

  return (
    <>
      {!showStatusIcon && renderDropdownField()}
      {showStatusIcon && (
        <Flex {...dropdownWithStatusIconWrapperStyle}>
          {renderDropdownField()}
          {renderStatusIcons()}
        </Flex>
      )}
    </>
  );

  function renderStatusIcons() {
    return (
      <Box>
        {displayIcon && hasError && (
          <Box {...errorIconStyle} data-testid="inputIconError">
            <Error24 />
          </Box>
        )}
        {displayIcon && isValid && (
          <Box {...errorIconStyle} data-testid="inputIconSuccess">
            <Success24 />
          </Box>
        )}
      </Box>
    );
  }

  function renderDropdownField() {
    return (
      <Menu gutter={1} isOpen={isOpen} {...dropdownStyles?.menuStyles} matchWidth={matchWidth}>
        <Box w="full" pos="relative" {...dropdownStyles?.wrapperStyles}>
          {props.label && (
            <Text
              pos="absolute"
              color={getLabelColor(isOpen)}
              {...labelStyles}
              {...(dropdownStyles ? dropdownStyles.labelStyles : {})}
            >
              {props.label}
            </Text>
          )}
          <Box ref={ref} as="span" {...dropdownStyles?.menuButtonWrapperStyles}>
            <MenuButton
              onClick={() => {
                setIsOpen(!isOpen);
                onDisplayContent?.(!isOpen);
              }}
              _hover={{
                color: 'darkGrey2',
                ...dropdownStyles?.errorHoverMenuButtonStyles,
                ...(isOpen
                  ? {
                      border: '2px solid teal',
                      borderColor: 'teal',
                      borderWidth: '2px',
                    }
                  : {
                      border: '1px solid var(--chakra-colors-darkGrey1)',
                      borderColor: 'var(--chakra-colors-darkGrey1)',
                      borderWidth: '1px',
                    }),
              }}
              _focus={{
                ...(!isOpen && {
                  border: hasError ? '2px solid var(--chakra-colors-error)' : '2px solid teal',
                }),
              }}
              {...dropdownStyles?.menuButtonStyles}
              disabled={props.disabled}
              type="button"
            >
              <Flex align="center" whiteSpace="nowrap" paddingX="10px">
                {!selectedValue?.icon && props.icon && <Box mr="md">{props.icon}</Box>}
                {selectedValue ? (
                  <Flex w="100%" textOverflow="ellipsis" overflow="hidden">
                    {renderDropdownOption(selectedValue, !!'buttonSelector')}
                  </Flex>
                ) : (
                  props.placeholder && (
                    <Box as="span" {...placeholderStyle}>
                      {props.placeholder}
                    </Box>
                  )
                )}
                {!props.skipChevron && (
                  <Icon ml="auto" svg={isOpen ? <ChevronUp /> : <ChevronDown />} />
                )}
              </Flex>
            </MenuButton>

            {CustomContent ? (
              <CustomContent
                isOpen={isOpen}
                onChange={props.onChange}
                setSelectedValue={setSelectedValue}
                setIsOpen={setIsOpen}
                options={options}
                baseDataTestId={baseDataTestId}
                dataTestId={dataTestId}
              />
            ) : (
              <MenuList {...dropdownStyles?.menuListStyles}>
                <MenuGroup w="full">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      type="button"
                      onClick={(event: any) => {
                        const target = event.target as HTMLButtonElement;
                        const value = target?.value;
                        const o = getOptionById(value);
                        setIsOpen(false);
                        if (o?.id === selectedValue?.id) return;
                        if (props.onChange) props.onChange(o);
                        setSelectedValue(o);
                        onBlur?.();
                      }}
                    >
                      {renderDropdownOption(option)}
                    </MenuItem>
                  ))}
                </MenuGroup>
              </MenuList>
            )}
          </Box>
        </Box>
      </Menu>
    );
  }

  function getLabelColor(isDropdownOpen: boolean) {
    let color = labelColorMap[variant];
    if (isDropdownOpen) {
      color = labelColorMap[`${variant}Open`];
    }
    if (props.disabled) {
      color = labelColorMap[`${variant}Disabled`];
    }
    return color;
  }

  function renderDropdownOption(
    option: DropdownOption | undefined,
    buttonSelector = false
  ): ReactNode {
    return (
      <>
        {option?.icon && <Box pr="md">{option.icon}</Box>}
        {buttonSelector ? <Box overflow="hidden">{option?.label}</Box> : option?.label}
      </>
    );
  }
  function getOptionById(option: string): DropdownOption | undefined {
    return options.find((o) => String(o.id) === option);
  }
}

const labelStyles = {
  top: '-7px',
  left: 'var(--chakra-space-xmd)',
  bgColor: 'var(--chakra-colors-baseWhite)',
  fontSize: 'var(--chakra-fontSizes-sm)',
  lineHeight: 'var(--chakra-lineHeights-1)',
  pr: 'xs',
  pl: 'xs',
  m: '0',
  _hover: {
    color: 'darkGrey2',
  },
};

const labelColorMap = {
  default: 'var(chakra-colors-darkGrey1)',
  defaultOpen: 'teal',
  defaultDisabled: 'var(--chakra-colors-lightGrey2)',
  error: 'var(--chakra-colors-error)',
  errorOpen: 'var(--chakra-colors-error)',
  errorDisabled: 'var(--chakra-colors-error)',
};

const placeholderStyle = {
  w: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const errorIconStyle = {
  ml: 'md',
  alignSelf: 'center',
};

const dropdownWithStatusIconWrapperStyle = {
  align: 'center',
  w: 'full',
};

export default memo(Dropdown);
