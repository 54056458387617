import { Divider, type DividerProps, Flex, type FlexProps, Heading } from '@chakra-ui/react';
import { type ReactNode } from 'react';

interface Props extends FlexProps {
  children: ReactNode;
  title: string;
}

export default function CardWithDivider({ children, title, ...otherProps }: Props) {
  return (
    <Flex {...containerStyle} {...otherProps}>
      <Heading as="h1" fontSize="3xxxl" p={{ mobile: 0, sm: '1.25rem' }}>
        {title}
      </Heading>
      <Divider {...dividerStyle} />
      {children}
    </Flex>
  );
}

const dividerStyle = {
  height: '0.2rem',
  orientation: 'horizontal',
  bg: '#008080',
  width: '8rem',
  borderRadius: '20px',
} as DividerProps;

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  height: 'fit-content',
  p: '4rem 2rem',
  bg: '#e6f3f3',
  gap: '1.5rem',
  textAlign: 'center',
} as FlexProps;
