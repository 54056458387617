import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgError24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    color="teal"
    {...props}
  >
    <path
      fill={props.color || '#D90941'}
      fillRule="evenodd"
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0Zm0 1.5C6.201 1.5 1.5 6.201 1.5 12S6.201 22.5 12 22.5 22.5 17.799 22.5 12 17.799 1.5 12 1.5Zm0 15a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm0-11.625c.57 0 1.04.423 1.115.972l.01.153v7.5a1.125 1.125 0 0 1-2.24.153l-.01-.153V6c0-.621.504-1.125 1.125-1.125Z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgError24);
export default Memo;
