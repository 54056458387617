import { ChakraProvider, Text } from '@chakra-ui/react';
import theme from './theme';
import { CookieProvider, ReservationProvider } from 'utils';
import { CheckOutPage, HomePage, SelectCarPage, SelectInsurancePage } from 'pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import ConfirmPage from './pages/Confirm.page';

export function App() {
  return (
    <Suspense fallback={<Text>Loading ..</Text>}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <ReservationProvider>
            <CookieProvider>
              <Routes>
                <Route path="/check-out" element={<CheckOutPage />} />
                <Route path="/select-insurance" element={<SelectInsurancePage />} />
                <Route path="/select-car" element={<SelectCarPage />} />
                <Route path="/confirm" element={<ConfirmPage />} />
                <Route path="/" element={<HomePage />}></Route>
              </Routes>
            </CookieProvider>
          </ReservationProvider>
        </ChakraProvider>
      </BrowserRouter>
    </Suspense>
  );
}
