import { Flex } from '@chakra-ui/react';
import HeaderDesktop from './HeaderDesktop.component';
import HeaderMobile from './HeaderMobile.component';

export default function Header() {
  return (
    <Flex h="4rem" w="100%" bgColor="lightGrey5">
      <HeaderDesktop />
      <HeaderMobile />
    </Flex>
  );
}
