import { ProgressLayout } from '../components/layout';
import { CheckOut } from '../components/CreateReservation';

export function CheckOutPage() {
  return (
    <ProgressLayout progress="99.99">
      <CheckOut />
    </ProgressLayout>
  );
}
