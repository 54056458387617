import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgBagaj = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 0.96 0.96"
    color="teal"
    {...props}
  >
    <path
      stroke={props.color || '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.08}
      d="M.32.24A.08.08 0 0 1 .4.16h.16a.08.08 0 0 1 .08.08V.8H.32V.24Z"
    />
    <path
      stroke={props.color || '#000'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.08}
      d="M.2.32h.56A.08.08 0 0 1 .84.4v.32A.08.08 0 0 1 .76.8H.2A.08.08 0 0 1 .12.72V.4A.08.08 0 0 1 .2.32z"
    />
  </svg>
);
const Memo = memo(SvgBagaj);
export default Memo;
