import { Box, BoxProps, Flex, FlexProps, Image, Text } from '@chakra-ui/react';
import { Card } from 'components';
import CarDetails from 'components/CreateReservation/SelectCar/CarItem/CarDetails';
import { Car } from 'types';

export interface Props {
  displayCarInformation?: boolean;
  displayInsuranceInfo?: boolean;
  car: Car;
  insuranceWithGuaranty: boolean;
  pickUpLocation: string;
  datePickUpFormat: string;
  hourPickUpFormat: string;
  dropOffLocation: string;
  dateDropOffFormat: string;
  hourDropOffFormat: string;
  reservationPeriodInDays: number;
  reservationPeriodLabel: string;
}

export function ReservationSummaryDesktop({
  displayCarInformation,
  displayInsuranceInfo,
  car,
  insuranceWithGuaranty,
  pickUpLocation,
  datePickUpFormat,
  hourPickUpFormat,
  dropOffLocation,
  dateDropOffFormat,
  hourDropOffFormat,
  reservationPeriodLabel,
  reservationPeriodInDays,
}: Props) {
  return (
    <Card display={{ mobile: 'none', lg: 'flex' }}>
      <Flex flexDirection="column">
        <Flex {...reservationSummaryStyle}>
          {car && displayCarInformation && (
            <Flex flexDir="column">
              <Text fontSize="2xl" color="darkGrey2">
                {car.name}
              </Text>
              <Image src={car.image} width="60%" mt="md" />

              <CarDetails
                bags={car.bags}
                doors={car.doors}
                gearbox={car.gearbox}
                minAge={car.minAge}
                numberSeats={car.numberSeats}
              />
              <Box {...delimiterStyle} mt="md" />
            </Flex>
          )}

          {displayCarInformation && (
            <Flex flexDir="column" gap="md" w="100%">
              <Text fontWeight="bold" color="darkGrey2" fontSize="lg">
                Total cost
              </Text>
              <Text fontSize="2xl" color="darkGrey1">
                {car.reservationPrice +
                  (insuranceWithGuaranty && displayInsuranceInfo ? car.insuranceGuaranty : 0) +
                  (!insuranceWithGuaranty && displayInsuranceInfo
                    ? car.insurancePerDay * reservationPeriodInDays
                    : 0)}
                €
              </Text>
              <Text as="span" fontWeight="lighter">
                Cost: {car.reservationPrice}€
              </Text>
            </Flex>
          )}

          {insuranceWithGuaranty && displayInsuranceInfo && (
            <Flex flexDir="column" gap="md" width="100%">
              <Text as="span" fontWeight="lighter">
                Garantie: €{car.insuranceGuaranty}
              </Text>
              <Box {...delimiterStyle} />
            </Flex>
          )}
          {!insuranceWithGuaranty && displayInsuranceInfo && (
            <Flex flexDir="column" gap="md" width="100%">
              <Text as="span" fontWeight="lighter">
                Asigurare Full Casco: {car.insurancePerDay * reservationPeriodInDays}€
              </Text>
              <Box {...delimiterStyle} />
            </Flex>
          )}
          <Flex flexDirection="column" gap="sm">
            <Text as="span" fontWeight="bold" color="darkGrey2" fontSize="md">
              Preluare
            </Text>
            <Text as="span" fontWeight="lighter">
              {pickUpLocation}
            </Text>
            <Text as="span" fontWeight="lighter">
              {datePickUpFormat} / {hourPickUpFormat}
            </Text>
          </Flex>

          <Box {...delimiterStyle} />

          <Flex flexDirection="column" gap="sm">
            <Text as="span" fontWeight="bold" color="darkGrey2" fontSize="md">
              Returnare
            </Text>
            <Text as="span" fontWeight="lighter">
              {pickUpLocation}
            </Text>
            <Text as="span" fontWeight="lighter">
              {dateDropOffFormat} / {hourDropOffFormat}
            </Text>
          </Flex>
          <Box {...delimiterStyle} />
          <Text>
            Pentru - {reservationPeriodInDays} {reservationPeriodLabel}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

const reservationSummaryStyle = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  borderRadius: '25px',
  padding: '12px',
  width: '300px',
  fontSize: 'md',
  lineHeight: '1',
  color: 'darkGrey4',
  fontWeight: 'bold',
  my: 'md',
  gap: '20px',
} as FlexProps;

const delimiterStyle = {
  width: '100%',
  height: '1px',
  bgColor: 'var(--chakra-colors-lightGrey3)',
  alignSelf: 'center',
} as BoxProps;
