export const mockCars = [
  {
    name: 'Dacia Logan',
    category: 'Economy',
    numberSeats: 5,
    gearbox: 'manual',
    doors: 5,
    bags: 3,
    minAge: 18,
    price: 25,
    insurancePerDay: 12,
    insuranceGuaranty: 200,
    image: '/images/cars/dacia_logan.png',
  },
  {
    name: 'Dacia Sandero Stepway',
    category: 'Economy',
    numberSeats: 5,
    gearbox: 'manual',
    doors: 5,
    bags: 2,
    minAge: 18,
    price: 27,
    insurancePerDay: 14,
    insuranceGuaranty: 250,
    image: '/images/cars/dacia_sandero_stepway.png',
  },
  {
    name: 'Fiat Tipo',
    category: 'Sedan',
    numberSeats: 5,
    gearbox: 'manual',
    doors: 5,
    bags: 3,
    minAge: 18,
    price: 30,
    insurancePerDay: 15,
    insuranceGuaranty: 300,
    image: '/images/cars/fiat_tipo.png',
  },
  {
    name: 'Renault Megane',
    category: 'Stationwagon / Estate',
    numberSeats: 5,
    gearbox: 'automatic',
    doors: 5,
    bags: 4,
    minAge: 18,
    price: 50,
    insurancePerDay: 30,
    insuranceGuaranty: 500,
    image: '/images/cars/renaul_megan_estate.png',
  },
  {
    name: 'Renault Clio',
    category: 'Econmoy',
    numberSeats: 5,
    gearbox: 'automatic',
    doors: 5,
    bags: 2,
    minAge: 18,
    price: 40,
    insurancePerDay: 25,
    insuranceGuaranty: 300,
    image: '/images/cars/renaul_clio.png',
  },
  {
    name: 'Toyota CHR',
    category: 'Hybrid',
    numberSeats: 5,
    gearbox: 'automatic',
    doors: 5,
    bags: 2,
    minAge: 18,
    price: 55,
    insurancePerDay: 30,
    insuranceGuaranty: 500,
    image: '/images/cars/toyota_chr.png',
  },
  {
    name: 'Toyota Corolla estate',
    category: 'Hybrid',
    numberSeats: 5,
    gearbox: 'automatic',
    doors: 5,
    bags: 4,
    minAge: 18,
    price: 50,
    insurancePerDay: 30,
    insuranceGuaranty: 500,
    image: '/images/cars/toyoa_corolla.png',
  },
  {
    name: 'Dacia Duster',
    category: 'Economy SUV',
    numberSeats: 5,
    gearbox: 'manual',
    doors: 5,
    bags: 4,
    minAge: 18,
    price: 65,
    insurancePerDay: 35,
    insuranceGuaranty: 500,
    image: '/images/cars/dacia_duster.png',
  },
];
