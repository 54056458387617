import { Header } from 'components';
import { Flex, Grid, Progress, Text, VStack } from '@chakra-ui/react';
import React from 'react';

export function ProgressLayout(props: any) {
  return (
    <VStack minH="100vh" height="100vh">
      <Header />
      <Flex flexDirection="column" width="100%" maxWidth="1300px">
        <Grid templateColumns="1fr auto" alignItems="center" my="xs">
          <Progress
            hasStripe
            value={props.progress}
            mb="5%"
            mx="5%"
            isAnimated
            colorScheme="teal"
            marginBottom="0"
            marginInlineStart={{ mobile: '5%', xl: '0' }}
          />
          <Text fontSize="md" color="darkGrey2" mx="xs">
            {props.progress}%
          </Text>
        </Grid>
        {props.children}
      </Flex>
    </VStack>
  );
}
