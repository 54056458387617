import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgCeasuri = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={32}
    height={32}
    viewBox="0 0 20.491 20.491"
    color="teal"
    {...props}
  >
    <path d="M8.965 12.011c0 .706.574 1.281 1.281 1.281.706 0 1.281-.574 1.281-1.281a.32.32 0 0 0-.64 0 .642.642 0 1 1-1.253-.19.309.309 0 0 0 .046-.111.641.641 0 0 1 .565-.34.32.32 0 0 0 0-.64c-.417 0-.788.2-1.022.51L4.346 9.229a.32.32 0 0 0-.418.174.32.32 0 0 0 .174.418l4.875 2.01a1.272 1.272 0 0 0-.013.18z" />
    <path d="M17.49 4.766a10.18 10.18 0 0 0-7.245-3.001C7.508 1.765 4.936 2.831 3 4.766s-3 4.508-3 7.245c0 2.004.581 3.947 1.679 5.619a.32.32 0 0 0 .443.092.32.32 0 0 0 .092-.443 9.604 9.604 0 0 1-.716-1.298l.883-.366a.32.32 0 0 0-.245-.591l-.883.366a9.543 9.543 0 0 1-.607-3.058h.955a.32.32 0 0 0 0-.64H.646c.034-1.06.24-2.091.604-3.06l.887.367a.319.319 0 0 0 .122.024.32.32 0 0 0 .123-.616l-.888-.367a9.589 9.589 0 0 1 1.737-2.591l.675.675a.32.32 0 0 0 .452 0 .32.32 0 0 0 0-.453l-.675-.675a9.58 9.58 0 0 1 2.591-1.737l.367.887a.32.32 0 0 0 .418.173.32.32 0 0 0 .173-.418l-.366-.886a9.59 9.59 0 0 1 3.06-.604v.955a.32.32 0 0 0 .64 0v-.955c1.06.034 2.091.24 3.06.604l-.367.887a.32.32 0 0 0 .591.245l.367-.887a9.568 9.568 0 0 1 2.59 1.737l-.675.675a.32.32 0 0 0 0 .453.32.32 0 0 0 .452 0l.675-.675a9.58 9.58 0 0 1 1.737 2.591l-.887.367a.32.32 0 1 0 .245.591l.887-.367c.364.969.569 2 .604 3.06h-.955a.32.32 0 0 0 0 .64h.955a9.592 9.592 0 0 1-.607 3.058l-.883-.366a.32.32 0 0 0-.245.591l.883.366a9.601 9.601 0 0 1-.716 1.298.32.32 0 1 0 .535.351 10.201 10.201 0 0 0 1.679-5.619 10.18 10.18 0 0 0-3.001-7.245z" />
    <path d="M13.12 15.525H7.36a.962.962 0 0 0-.96.96v1.28c0 .529.431.96.96.96h5.76c.529 0 .96-.431.96-.96v-1.28a.962.962 0 0 0-.96-.96zm.32 2.24a.32.32 0 0 1-.32.32H7.36a.32.32 0 0 1-.32-.32v-1.28a.32.32 0 0 1 .32-.32h5.76a.32.32 0 0 1 .32.32v1.28z" />
    <path d="M10.56 16.805h-.64a.32.32 0 0 0 0 .64h.64a.32.32 0 0 0 0-.64zm-1.6 0h-.64a.32.32 0 0 0 0 .64h.64a.32.32 0 0 0 0-.64zm3.2 0h-.64a.32.32 0 0 0 0 .64h.64a.32.32 0 0 0 0-.64z" />
  </svg>
);
const Memo = memo(SvgCeasuri);
export default Memo;
