import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import { differenceInDays, differenceInMinutes, parse } from 'date-fns';
import { isStringValid } from './validation';

export function formatDate(date: string | Date, formatDate: string, language?: string) {
  return format(new Date(date), formatDate, {
    locale: language === 'de' ? de : enGB,
  });
}

export function formatDataTestId(prefix: string | null = '', dataTestId: string | null = '') {
  const previousString = isStringValid(prefix) ? `${prefix}-` : '';
  const testId = isStringValid(dataTestId) ? dataTestId : '';
  return `${previousString}${testId}`;
}

export function calculateDiffInDays(
  datePickUp: Date,
  hourPickUp: Date,
  dateDropOff: Date,
  hourDropOff: Date
) {
  let reservationPeriodInDays = 1;
  let datePickUpFormat = formatDate(datePickUp, 'dd-MM-yyy');
  let hourPickUpFormat = formatDate(hourPickUp, 'HH:mm');

  let dateDropOffFormat = formatDate(dateDropOff, 'dd-MM-yyy');
  let hourDropOffFormat = formatDate(hourDropOff, 'HH:mm');

  const dateTime1 = parse(
    `${datePickUpFormat} ${hourPickUpFormat}`,
    'dd-MM-yyyy HH:mm',
    new Date()
  );
  const dateTime2 = parse(
    `${dateDropOffFormat} ${hourDropOffFormat}`,
    'dd-MM-yyyy HH:mm',
    new Date()
  );

  const daysDiff = differenceInDays(dateTime2, dateTime1);
  const minutesDiff = differenceInMinutes(dateTime2, dateTime1) % 60;

  if (daysDiff > 0) {
    reservationPeriodInDays = daysDiff;
    if (minutesDiff > 0) {
      reservationPeriodInDays += 1;
    }
  }

  return reservationPeriodInDays;
}
