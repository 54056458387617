export const getCookie = (cookieName: string): any => {
  let val = null;
  try {
    val = document?.cookie
      ?.split('; ')
      ?.find?.((row) => row?.startsWith?.(cookieName))
      ?.split('=')[1];
  } catch (e) {
    return e;
  }
  return decodeURI(val as string);
};

export function updateCookieValueWithExtraData(cookieName: string, extraData: any) {
  // Obține valoarea curentă a cookie-ului existent
  let cookieData = JSON.parse(getCookie(cookieName));

  cookieData = { ...cookieData, ...extraData };

  document.cookie = 'RESERVATION_DATA=' + encodeURI(JSON.stringify(cookieData));
}

export const setCookie = (
  name: string,
  value: any,
  time: number,
  path = '/',
  overrideDomain = 'www.'
) => {
  let expires = '';
  if (time) {
    const d = new Date();
    // Sets to minutes
    d.setTime(d.getTime() + time * 60 * 1000);
    expires = `${'expires='}${d.toUTCString()}`;
  }

  const cookiePath = `path=${path}`;
  const domain = `${'domain='}${window.location.hostname.replace('www.', overrideDomain)}`;
  document.cookie = `${name}=${encodeURI(value)};${expires};${cookiePath};${domain}`;
};

export const deleteCookie = (cookieName: string): any => {
  const expires = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  const path = 'path=/';
  const domain = `${'domain='}${window.location.hostname}`;
  document.cookie = `${cookieName}=;${expires};${path};${domain}`;
};
