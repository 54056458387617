export function isStringValid(item: string | null | undefined) {
  return item !== null && item !== undefined && item !== '';
}

export function validateName(name: string) {
  const lettersOnlyRegex = /^[A-Za-z\s]+$/;
  return lettersOnlyRegex.test(name);
}

export function validateEmail(email: string) {
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

  return pattern.test(email);
}

export function validatePhone(phone: string) {
  const internationalPhoneRegex = /^\+(?:\d{1,4})?(?:[ -]?\d{1,4}){1,14}$/;
  const internationalPhoneWithoutPlusRegex = /^(?:\d{1,4})?(?:[ -]?\d{1,4}){1,14}$/;

  return internationalPhoneRegex.test(phone) || internationalPhoneWithoutPlusRegex.test(phone);
}
