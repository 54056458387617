import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="-3 0 20 20"
    color="teal"
    {...props}
  >
    <path
      fill={props.color || '#000'}
      fillRule="evenodd"
      d="M7 9.219a2 2 0 1 1-.001-3.999A2 2 0 0 1 7 9.22M7 0a7 7 0 0 0-7 7c0 3.866 7 13 7 13s7-9.134 7-13a7 7 0 0 0-7-7"
    />
  </svg>
);
const Memo = memo(SvgPin);
export default Memo;
