import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgRoutePlan = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="teal"
    stroke="teal"
    strokeWidth={0}
    viewBox="0 0 68.266 68.266"
    color="teal"
    {...props}
  >
    <path
      stroke="none"
      d="M55.467 42.667H42.666c-2.347 0-4.267-1.92-4.267-4.267s1.92-4.267 4.267-4.267h12.801S68.268 19.867 68.268 12.8 62.535 0 55.467 0 42.666 5.733 42.666 12.801c0 3.399 2.961 8.453 6.039 12.801h-6.039c-7.053 0-12.801 5.746-12.801 12.801s5.746 12.801 12.801 12.801h12.801c2.347 0 4.267 1.92 4.267 4.267s-1.92 4.267-4.267 4.267H24.734c-2.133 3.307-4.507 6.36-6.307 8.534h37.041c7.053 0 12.801-5.746 12.801-12.801S62.523 42.67 55.468 42.67zm0-34.133c2.361 0 4.267 1.906 4.267 4.267s-1.906 4.267-4.267 4.267-4.267-1.906-4.267-4.267 1.906-4.267 4.267-4.267zM12.801 34.133C5.734 34.133 0 39.866 0 46.934s12.801 21.333 12.801 21.333 12.801-14.266 12.801-21.333-5.733-12.801-12.801-12.801zm0 17.067c-2.361 0-4.267-1.906-4.267-4.267s1.906-4.267 4.267-4.267 4.267 1.906 4.267 4.267-1.906 4.267-4.267 4.267z"
    />
  </svg>
);
const Memo = memo(SvgRoutePlan);
export default Memo;
