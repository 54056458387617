import React, { createContext, useContext, useState } from 'react';
import { addDays } from 'date-fns';
import type { Car } from 'types';

export interface ReservationState {
  datePickUp: Date;
  hourPickUp: Date | string;
  dateDropOff: Date;
  hourDropOff: Date | string;
  pickUpLocation: string;
  dropOffLocation: string;
  reservationPeriodInDays: number;
  car: Car;
  price: string;
  insuranceWithGuaranty: boolean;
  insurancePrice: number;
  name: string;
  phone: string;
  email: string;
  message: string;
  age: string;
}

// Definiți tipul inițial pentru context
interface ReservationStateType {
  reservationState: ReservationState;
  updateReservation: (data: any) => void;
}

// Crearea contextului global cu tipul inițial definit
const ReservationContext = createContext<ReservationStateType | undefined>(undefined);

export function useReservationState() {
  const context = useContext(ReservationContext);
  if (context === undefined) {
    throw new Error('useReservationState must be used within a ReservationProvider');
  }
  return context;
}

export function ReservationProvider({ children }: { children: React.ReactNode }) {
  const initialReservationState: ReservationState = {
    datePickUp: new Date(),
    hourPickUp: new Date(0, 0, 0, 9, 0, 0, 0),
    dateDropOff: addDays(new Date(), 1),
    hourDropOff: new Date(0, 0, 0, 9, 0, 0, 0),
    pickUpLocation: 'Iasi',
    dropOffLocation: 'Iasi',
    reservationPeriodInDays: 1,
    car: {
      reservationPrice: 0,
      price: 0,
      bags: 0,
      name: '',
      category: '',
      doors: 0,
      gearbox: '',
      image: '',
      minAge: 0,
      numberSeats: 0,
      insuranceGuaranty: 0,
      insurancePerDay: 0,
    },
    price: '',
    insuranceWithGuaranty: false,
    insurancePrice: 0,
    name: '',
    phone: '',
    email: '',
    message: '',
    age: '30',
  };

  const [reservationState, setReservationState] = useState(initialReservationState);

  // Funcție pentru a actualiza starea globală
  const updateReservation = (data: Partial<ReservationStateType>) => {
    setReservationState((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };

  const contextValue: ReservationStateType = {
    reservationState: reservationState,
    updateReservation,
  };

  return <ReservationContext.Provider value={contextValue}>{children}</ReservationContext.Provider>;
}
