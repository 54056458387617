import * as React from 'react';
import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    preserveAspectRatio="xMidYMid"
    viewBox="-0.185 0 32 32"
    color="teal"
    {...props}
  >
    <path
      fill="teal"
      d="m8.36 27.407.517.259c2.155 1.293 4.568 1.896 6.981 1.896 7.584 0 13.79-6.205 13.79-13.79a13.84 13.84 0 0 0-4.051-9.739c-2.586-2.586-6.033-4.051-9.739-4.051-7.584 0-13.79 6.205-13.703 13.876 0 2.585.776 5.085 2.068 7.239l.345.517-1.379 5.085 5.171-1.293Z"
    />
    <path
      fill="#FFF"
      d="M27.062 4.654C24.132 1.638 20.081 0 15.944 0 7.153 0 .086 7.153.172 15.858c0 2.758.776 5.43 2.069 7.843L0 31.888l8.36-2.155c2.327 1.293 4.912 1.896 7.498 1.896 8.705 0 15.772-7.153 15.772-15.858 0-4.223-1.638-8.188-4.568-11.118ZM15.944 28.958c-2.327 0-4.654-.603-6.636-1.81l-.517-.259-4.999 1.293 1.293-4.912-.345-.517C.948 16.634 2.758 8.532 8.963 4.74c6.205-3.792 14.22-1.982 18.013 4.223 3.792 6.205 1.982 14.22-4.223 18.013-1.982 1.293-4.395 1.982-6.809 1.982Zm7.584-9.566-.948-.431s-1.379-.603-2.241-1.034c-.086 0-.172-.086-.259-.086-.259 0-.431.086-.603.172 0 0-.086.086-1.293 1.465a.475.475 0 0 1-.431.259h-.086c-.086 0-.258-.086-.345-.172l-.431-.172c-.948-.431-1.81-.948-2.499-1.638-.172-.172-.431-.345-.603-.517-.603-.603-1.207-1.293-1.637-2.069l-.086-.172c-.086-.086-.086-.172-.172-.345 0-.172 0-.345.086-.431 0 0 .345-.431.603-.689.172-.172.259-.431.431-.603.172-.259.259-.603.172-.862-.086-.431-1.12-2.758-1.379-3.275-.172-.259-.345-.345-.603-.431h-.948c-.172 0-.345.086-.517.086l-.086.086c-.172.086-.345.259-.517.345-.172.172-.259.345-.431.517-.603.776-.948 1.724-.948 2.672 0 .689.172 1.379.431 1.982l.086.259a14.359 14.359 0 0 0 3.189 4.395l.345.345c.258.259.517.431.689.689 1.81 1.551 3.878 2.672 6.205 3.275.259.086.603.086.862.172h.862c.431 0 .948-.172 1.293-.345.258-.172.431-.172.603-.345l.172-.172c.172-.172.345-.258.517-.431.172-.172.345-.345.431-.517.172-.345.258-.776.345-1.206v-.603s-.086-.086-.259-.172Z"
    />
  </svg>
);
const Memo = memo(SvgWhatsapp);
export default Memo;
